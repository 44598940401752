import axios from "axios"
import { BASE_URL } from "../../utils/baseUrl"
import { MODE } from "../../utils/mode"


// Get All USER API
export const GETALL_USER_API = async () => {
    try {
        const response = await axios.get(`${BASE_URL}User/getall/${MODE}?page_no=1&page_size=1000`)
        return response  
    } catch (error) {
        console.log("GETALL_USER_API", error)
        throw(error)
    }
}

