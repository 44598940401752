import axios from "axios"
import { BASE_URL } from "../../utils/baseUrl"


// Get All USER API
export const GETALL_MODES = async () => {
    try {
        const response = await axios.get(`${BASE_URL}modes/getall/?page_number=1&page_size=11`)
        return response  
    } catch (error) {
        throw(error)
    }
}

