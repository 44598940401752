// creat add restro patner API 

import axios from "axios";
import { BASE_URL } from "../../utils/baseUrl";

export const STORE_CREAT_API = async (userData) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/stores/create`,
        userData
      );
      return response;
    } catch (error) {
      console.log("LOGIN_USER_API", error);
      throw error;
    }
  };