import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./service/redux-store";

// Css
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/swiper/swiper-bundle.min.css";
import "../node_modules/magnific-popup/dist/magnific-popup.css";
import "../node_modules/react-rangeslider/lib/index.css";
import "./assets/css/font-awesome.css";
import "./assets/css/font/flaticon.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/color4.css";
import "./index.css";
import "./assets/css/custom-style.css";
import "./assets/css/m-custom.css";
import "./assets/css/m-newfooter.css";
import "./assets/css/Add_restro.css";

createRoot(document.getElementById("Fringale")).render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
