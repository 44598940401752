import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GETALL_MODES } from "../redux-apis/mode";

// GETALL mode SLICE
export const GETALL_MODE_SLICE = createAsyncThunk(
  'async/modeGetall',
  async (thunkAPI) => {
    try {
      const response = await GETALL_MODES();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error ? error.response.data : 'An error occurred');
    }
  }
);

const modeSlice = createSlice({
  name: "mode",
  initialState: {
    loading: false,
    error: null,
    modes: [],
  },
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(GETALL_MODE_SLICE.pending, (state) => {
        state.error = null;
      })
      .addCase(GETALL_MODE_SLICE.fulfilled, (state, action) => {
        state.loading = false;
        state.modes = action.payload.data;
    })
      .addCase(GETALL_MODE_SLICE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ? action.payload : 'An error occurred';
      });
  }
});

export default modeSlice.reducer;
