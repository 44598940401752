import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orders: [],
};

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    addOrder(state, action) {
      const newOrder = action.payload;
      state.orders.push(newOrder);
      // Save orders to local storage after adding a new order
      localStorage.setItem('orders', JSON.stringify(state.orders));
    },
    clearOrders(state) {
      state.orders = [];
      // Clear orders from local storage
      localStorage.removeItem('orders');
    },
  },
}); 

export const { addOrder, clearOrders } = orderSlice.actions;

export default orderSlice.reducer;
