import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GETALL_USER_API } from "../redux-apis/user";

// GETALL USER SLICE
export const GETALL_USER_SLICE = createAsyncThunk(
  'async/userGetall',
  async (thunkAPI) => {
    try {
      const response = await GETALL_USER_API();
      return response.data;
    } catch (error) {
      console.log("GETALL_USER_SLICE", error);
      return thunkAPI.rejectWithValue(error ? error.response.data : 'An error occurred');
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false, 
    loading: false,
    error: null,
    users: [],
  },
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(GETALL_USER_SLICE.pending, (state) => {
        state.error = null;
      })
      .addCase(GETALL_USER_SLICE.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
    })
      .addCase(GETALL_USER_SLICE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ? action.payload : 'An error occurred';
      });
  }
});

export default userSlice.reducer;
