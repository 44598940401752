import { createSlice } from '@reduxjs/toolkit';

// Utility functions to handle local storage
const loadFavoritesFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('favoriteItems');
    return serializedState ? JSON.parse(serializedState) : [];
  } catch (e) {
    console.warn('Could not load favorite items from local storage', e);
    return [];
  }
};

const saveFavoritesToLocalStorage = (favoriteItems) => {
  try {
    const serializedState = JSON.stringify(favoriteItems);
    localStorage.setItem('favoriteItems', serializedState);
  } catch (e) {
    console.warn('Could not save favorite items to local storage', e);
  }
};

// Initial state with loading from local storage
const initialState = {
  favoriteItems: loadFavoritesFromLocalStorage(),
};

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    addItemToFavorite(state, action) {
      state.favoriteItems.push(action.payload);
      saveFavoritesToLocalStorage(state.favoriteItems); // Save to local storage
    },
    removeItemFromFavorite(state, action) {
      state.favoriteItems = state.favoriteItems.filter(
        (item) => item.id !== action.payload.id
      );
      saveFavoritesToLocalStorage(state.favoriteItems); // Save to local storage
    },
  },
});

export const { addItemToFavorite, removeItemFromFavorite } = favoriteSlice.actions;

export const selectFavoriteItems = (state) => state.favorite.favoriteItems;

export default favoriteSlice.reducer;
