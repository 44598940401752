import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STORE_CREAT_API } from "../redux-apis/store";

// create store SLICE
export const CREATE_STORE_SLICE = createAsyncThunk(
  'async/createStore',
  async (DATA,thunkAPI) => {
    try {
      const response = await STORE_CREAT_API(DATA);
      return response.data;
    } catch (error) {
      console.log("GETALL_USER_SLICE", error);
      return thunkAPI.rejectWithValue(error ? error.response.data : 'An error occurred');
    }
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState: {
    isLoggedIn: false, 
    loading: false,
    error: null,
    stores: [],
  },
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(CREATE_STORE_SLICE.pending, (state) => {
        state.error = null;
      })
      .addCase(CREATE_STORE_SLICE.fulfilled, (state, action) => {
        state.loading = false;
        state.stores = action.payload.data;
    })
      .addCase(CREATE_STORE_SLICE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ? action.payload : 'An error occurred';
      });
  }
});

export default storeSlice.reducer;
