import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CREATE_USER_API,
  EMAIL_LOGIN_USER_API,
  OTP_LOGIN_USER_API,
} from "../redux-apis/auth";



//SIGN UP USER SLICE
export const CREATE_USER_SLICE = createAsyncThunk(
  "async/userCreate",
  async (userData, thunkAPI) => {
    try {
      const response = await CREATE_USER_API(userData);
      return response.data;
    } catch (error) {
      console.log("CREATE_USER_SLICE", error);
      return thunkAPI.rejectWithValue(
        error ? error.response.data : "An error occurred"
      );
    }
  }
);

//SIGN UP USER SLICE
export const CREATE_STORE_USER_SLICE = createAsyncThunk(
  "async/storeUserCreate",
  async (userData, thunkAPI) => {
    try {
      const response = await CREATE_STORE_USER_SLICE(userData);
      return response.data;
    } catch (error) {
      console.log("CREATE_STORE_USER_SLICE", error);
      return thunkAPI.rejectWithValue(
        error ? error.response.data : "An error occurred"
      );
    }
  }
);

//OTP LOGIN USER SLICE
export const OTP_LOGIN_USER_SLICE = createAsyncThunk(
  "async/userOtpLogin",
  async (userData, thunkAPI) => {
    try {
      const response = await OTP_LOGIN_USER_API(userData);
      return response.data;
    } catch (error) {
      console.log("LOGIN_USER_SLICE", error);
      return thunkAPI.rejectWithValue(
        error ? error.response.data : "An error occurred"
      );
    }
  }
);

//OTP LOGIN USER SLICE
export const EMAIL_LOGIN_USER_SLICE = createAsyncThunk(
  "async/userEmailLogin",
  async (userData, thunkAPI) => {
    try {
      const response = await EMAIL_LOGIN_USER_API(userData);
      return response.data;
    } catch (error) {
      console.log("LOGIN_USER_SLICE_EMAIL", error);
      return thunkAPI.rejectWithValue(
        error ? error.response.data : "An error occurred"
      );
    }
  }
);

const authSlice = createSlice({
  name: "userAuth",
  initialState: {
    userInfo: null,
    storeUserInfo: null,
    isLoggedIn: false,
    loading: false,
    error: null,
    user: [],
    storeUser: [],
  },
  reducers: {
    clearUserInfo(state) {
      state.userInfo = null;
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CREATE_USER_SLICE.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CREATE_USER_SLICE.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
        state.isLoggedIn = true;
      })
      .addCase(CREATE_USER_SLICE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ? action.payload : "An error occurred";
      })

      .addCase(CREATE_STORE_USER_SLICE.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CREATE_STORE_USER_SLICE.fulfilled, (state, action) => {
        state.loading = false;
        state.storeUserInfo = action.payload;
        state.isLoggedIn = true;
      })
      .addCase(CREATE_STORE_USER_SLICE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ? action.payload : "An error occurred";
      })

      .addCase(OTP_LOGIN_USER_SLICE.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(OTP_LOGIN_USER_SLICE.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
        state.isLoggedIn = true;
      })
      .addCase(OTP_LOGIN_USER_SLICE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ? action.payload : "An error occurred";
      })

      .addCase(EMAIL_LOGIN_USER_SLICE.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(EMAIL_LOGIN_USER_SLICE.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
        state.isLoggedIn = true;
      })
      .addCase(EMAIL_LOGIN_USER_SLICE.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ? action.payload : "An error occurred";
      });
  },
});

export const { clearUserInfo } = authSlice.actions;
export default authSlice.reducer;
