import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAddress } from "../../utils/apiGeoCodding";

function getPosition() {
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
}

export const fetchLocation = createAsyncThunk(
  "user/fetchLocation",
  async function () {
    try {
      // 1) We get the user's geolocation position
      const positionObj = await getPosition();
      const position = {
        latitude: positionObj.coords.latitude,
        longitude: positionObj.coords.longitude,
      };

      const addressObj = await getAddress(position);
      return  addressObj ;
    } catch (error) {
      throw new Error("There was a problem getting your location, make sure to fill the field.");
    }
  }
)

export const fetchAddress = createAsyncThunk(
  "user/fetchAddress",
  async function () {
    try {
      // 1) We get the user's geolocation position
      const positionObj = await getPosition();
      const position = {
        latitude: positionObj.coords.latitude,
        longitude: positionObj.coords.longitude,
      };

      // 2) Then we use a reverse geocoding API to get a description of the user's address, so we can display it the order form, so that the user can correct it if wrong
      const addressObj = await getAddress(position);
      const address = `${addressObj?.locality},  ${addressObj?.localityInfo?.administrative[2].name} ${addressObj?.postcode}, ${addressObj?.countryName}`;
      // 3) Then we return an object with the data that we are interested in
      return { position, address };
    } catch (error) {
      throw new Error("There was a problem getting your address, make sure to fill the field.");
    }
  }
);

export const fetchCity = createAsyncThunk(
  "user/fetchCity", // Change the action type string to distinguish it from fetchAddress
  async function () {
    try {
      // 1) We get the user's geolocation position
      const positionObj = await getPosition();
      const position = {
        latitude: positionObj.coords.latitude,
        longitude: positionObj.coords.longitude,
      };

      const addressObj = await getAddress(position);
      const city = `${addressObj?.city}`; // Change the property name to city
      return { position, city }; // Change the payload property name to city
    } catch (error) {
      throw new Error("There was a problem getting your city, make sure to fill the field.");
    }
  }
);

const initialState = {
  status: "idle",
  position: {},
  getLocation: {},
  address: "",
  city:"",
  error: "",
};

const locationSlice = createSlice({
  name: "geoLocation",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchAddress.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(fetchCity.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(fetchLocation.pending, (state, action) => {
      state.status = "loading";
    });

    builder.addCase(fetchAddress.fulfilled, (state, action) => {
      state.position = action.payload.position;
      state.address = action.payload.address;
      state.status = "idle";
      state.error = ""; 
    });

    builder.addCase(fetchAddress.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error.message;
    });

    builder.addCase(fetchCity.fulfilled, (state, action) => {
      state.position = action.payload.position;
      state.city = action.payload.city; 
      state.status = "idle";
      state.error = ""; 
    });

    builder.addCase(fetchCity.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error.message;
    });

    builder.addCase(fetchLocation.fulfilled, (state, action) => {
      state.getLocation = action.payload; 
      state.status = "idle";
      state.error = ""; 
    });

    builder.addCase(fetchLocation.rejected, (state, action) => {
      state.status = "error";
      state.error = action.error.message;
    });
  },
});

export default locationSlice.reducer;
