import axios from "axios";
import { BASE_URL } from "../../utils/baseUrl";

// CREATE USER API
export const CREATE_USER_API = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}User/create`, userData);
    return response;
  } catch (error) {
    console.log("LOGIN_USER_API", error);
    throw error;
  }
};

// CREATE STORE USER API
export const CREATE_STORE_USER_API = async (userData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}users_stores/create`,
      userData
    );
    return response;
  } catch (error) {
    console.log("LOGIN_USER_API", error);
    throw error;
  }
};

// OTP LOGIN USER API
export const OTP_LOGIN_USER_API = async (userData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}User/loginWithPhoneOtp`,
      userData
    );
    return response;
  } catch (error) {
    console.log("LOGIN_USER_API", error);
    throw error;
  }
};

// LOGIN USER API
export const EMAIL_LOGIN_USER_API = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}User/login`, userData);
    return response;
  } catch (error) {
    console.log("LOGIN_USER_API", error);
    throw error;
  }
};


