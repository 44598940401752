// // cartSlice.js
// import { createSlice } from '@reduxjs/toolkit';
// import { saveToLocalStorageCart, getFromLocalStorageCart } from '../redux-apis/cart';

// const initialState = {
//   items: getFromLocalStorageCart('cart'), 
// };


// const cartSlice = createSlice({
//   name: 'cart',
//   initialState,
//   reducers: {
//     addItemToCart(state, action) {
//       state.items.push(action.payload);
//       saveToLocalStorageCart('cart', state.items);
//     },
//     removeItemFromCart(state, action) {
//       state.items = state.items.filter(item => item.id !== action.payload.id);
//       saveToLocalStorageCart('cart', state.items);
//     },
//     clearCart(state) {
//       state.items = [];
//       saveToLocalStorageCart('cart', state.items);
//     },
//   },
// });

// export const { addItemToCart, removeItemFromCart, clearCart } = cartSlice.actions;
// export default cartSlice.reducer;





// cartSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { saveToLocalStorageCart, getFromLocalStorageCart } from '../redux-apis/cart';

const initialState = {
  items: getFromLocalStorageCart('cart') || [], 
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart(state, action) {
      const existingItem = state.items.find(item => item.id === action.payload.id);
      if (existingItem) {
        existingItem.quantity++;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
      saveToLocalStorageCart('cart', state.items);
    },
    removeItemFromCart(state, action) {
      state.items = state.items.filter(item => item.id !== action.payload.id);
      saveToLocalStorageCart('cart', state.items);
    },
    incrementItemQuantity(state, action) {
      const item = state.items.find(item => item.id === action.payload.id);
      if (item) {
        item.quantity++;
      }
      saveToLocalStorageCart('cart', state.items);
    },
    decrementItemQuantity(state, action) {
      const item = state.items.find(item => item.id === action.payload.id);
      if (item && item.quantity > 1) {
        item.quantity--;
      } else {
        state.items = state.items.filter(item => item.id !== action.payload.id);
      }
      saveToLocalStorageCart('cart', state.items);
    },
    clearCart(state) {
      state.items = [];
      saveToLocalStorageCart('cart', state.items);
    },
  },
});

export const { addItemToCart, removeItemFromCart, incrementItemQuantity, decrementItemQuantity, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
