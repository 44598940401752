import React from 'react'
import './css/LoaderPage.css'

const LoaderPage = () => {
    return (
        <div className='LoaderPage'>
            <svg viewBox="25 25 50 50">
                <circle r="20" cy="50" cx="50"></circle>
            </svg>
        </div>
    )
}

export default LoaderPage