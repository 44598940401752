import { createSlice } from '@reduxjs/toolkit';

export const orderCompleteSlice = createSlice({
  name: 'order',
  initialState: {
    isCompleted: false,
  },
  reducers: {
    completeOrder: state => {
      state.isCompleted = true;
    },
  },
});

export const { completeOrder } = orderCompleteSlice.actions;

export const selectIsOrderCompleted = state => state.order.isCompleted;

export default orderCompleteSlice.reducer;
