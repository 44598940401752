import { configureStore } from "@reduxjs/toolkit";
import modeSlice from './redux-slices/mode'
import authSlice from "./redux-slices/auth";
import userSlice from "./redux-slices/user";
import { thunk } from "redux-thunk";
import storeSlice from "./redux-slices/store";
import locationSlice from "./redux-slices/geolocation"
import cartSlice from "./redux-slices/cart"
import orderSlice from "./redux-slices/order"
import isLoginReducer from "./redux-slices/isLogin"
import  orderCompleteSlice  from "./redux-slices/orderComplete";
import favoriteReducer from "./redux-slices/favoriteSlice"

export const store = configureStore({
    reducer: {
        MODE_STORE: modeSlice,
        AUTH_STORE: authSlice,
        USER_STORE: userSlice,
        STORE_DATA:storeSlice,
        GEO_LOCATION: locationSlice,
        CART: cartSlice,
        ORDER: orderSlice,
        IS_LOGIN: isLoginReducer,
        ORDER_COMPLETE: orderCompleteSlice,
        FAVORITE: favoriteReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

