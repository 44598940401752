import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { StoreAdmin } from "./assets/Store-admin/Store_admin";
import LoaderPage from "./components/layouts/LoaderPage";
import "./scrollToTop.css";





// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Homeone = React.lazy(() => import("./components/pages/Homeone"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
const Homefour = React.lazy(() => import("./components/pages/Homefour"));
const About = React.lazy(() => import("./components/pages/About"));
const Profile = React.lazy(() =>
  import("./components/pages/Fringale/Profile/main")
);
const Help = React.lazy(() => import("./components/pages/Fringale/Help/main"));
const Blog = React.lazy(() => import("./components/pages/Blog"));
const Blogtwo = React.lazy(() => import("./components/pages/Blogtwo"));
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
const Restaurant = React.lazy(() => import("./components/pages/Restaurant"));
const Restaurantone = React.lazy(() =>
  import("./components/pages/Restaurantone")
);
const Restauranttwo = React.lazy(() =>
  import("./components/pages/Restauranttwo")
);
const Addrestaurant = React.lazy(() =>
  import("./components/pages/Fringale/add_restro/Addrestaurant")
);
const Checkout = React.lazy(() => import("./components/pages/Checkout"));
const Exdeals = React.lazy(() => import("./components/pages/Exdeals"));
const Geolocator = React.lazy(() => import("./components/pages/Geolocator"));
const Listview = React.lazy(() => import("./components/pages/Listview"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Orderdetails = React.lazy(() =>
  import("./components/pages/Orderdetails")
);
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Error = React.lazy(() => import("./components/pages/Error"));
const UserAccount = React.lazy(() =>
  import("./components/pages/Fringale/UserAccount/UserAccount")
);
const SearchFood = React.lazy(() =>
  import("./components/pages/Fringale/Search/Search")
);
const CheckOutFood = React.lazy(() =>
  import("./components/pages/Fringale/checkout/Content")
);
const Store = React.lazy(() =>
  import("./components/pages/Fringale/store/Store")
);
const Storetwo = React.lazy(() =>
  import("./components/pages/Fringale/store/Storetwo")
);
const StoreView = React.lazy(() =>
  import("./components/pages/Fringale/Component/StoreView")
);
const Saved = React.lazy(() =>
  import("./components/pages/Fringale/megaMenu/Saved")
);

const TermAndCondition = React.lazy(() =>
  import("./components/pages/Fringale/ContentManagement/TermAndCondition")
);
const HelpAndSupport = React.lazy(() =>
  import(
    "./components/pages/Fringale/ContentManagement/helpAndSupport/HelpAndSuport"
  )
);
const FAQ = React.lazy(() =>
  import("./components/pages/Fringale/ContentManagement/helpAndSupport/Faq")
);
const Stores = React.lazy(() =>
  import("./components/pages/Fringale/stores/Store")
);
const DineOut = React.lazy(() =>
  import("./components/pages/Fringale/dineout/DineOut")
);
const ReservationStores = React.lazy(() =>
  import("./components/pages/Fringale/dineout/ReservationStores")
);
const ReservationStore = React.lazy(() =>
  import("./components/pages/Fringale/dineout/dineOutStorePage/ReservationStore")
);
const BookATable = React.lazy(() =>
  import("./components/pages/Fringale/dineout/bookTable/BookATable")
);
const PrivacyPolicy = React.lazy(() =>
  import(
    "./components/pages/Fringale/ContentManagement/privacyPolicy/PrivacyPolicy"
  )
);

function App() {
  const [showBtn, setShowBtn] = useState("myBtn none");

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 600 ||
      document.documentElement.scrollTop > 600
    ) {
      setShowBtn("myBtn");
    } else {
      setShowBtn("none");
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <>
      <Router basename={"/"}>
        <Suspense
          fallback={
            <div>
              <LoaderPage />
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={Home} />
            {/* <Route exact path="/customer/home-v1" component={Homeone} /> */}
            {/* <Route exact path="/customer/home-v2" component={Hometwo} /> */}
            {/* <Route exact path="/customer/home-v3" component={Homethree} /> */}
            {/* <Route exact path="/customer/home-v4" component={Homefour} /> */}
            <Route exact path="/customer/about" component={About} />
            {/* <Route exact path="/blog" component={Blog} /> */}
            {/* <Route exact path="/blog-v2" component={Blogtwo} /> */}
            {/* <Route exact path="/blog-details" component={Blogdetails} /> */}
            {/* <Route exact path="/restaurant" component={Restaurant} /> */}
            {/* <Route exact path="/restaurant-v1" component={Restaurantone} /> */}
            {/* <Route exact path="/restaurant-v2" component={Restauranttwo} /> */}
            {/* <Route exact path="/add-store" component={Addrestaurant} /> */}
            {/* <Route exact path="/checkout" component={Checkout} /> */}
            {/* <Route exact path="/ex-deals" component={Exdeals} /> */}
            {/* <Route exact path="/geo-locator" component={Geolocator} /> */}
            {/* <Route exact path="/list-view" component={Listview} /> */}
            {/* <Route exact path="/login" component={Login} /> */}
            {/* <Route exact path="/register" component={Register} /> */}
            <Route
              exact
              path="/customer/order-details"
              component={Orderdetails}
            />
            <Route exact path="/customer/contact" component={Contact} />
            {/* <Route exact path="/error-page" component={Error} /> */}
            {/* <Route exact path="/user-account" component={UserAccount} /> */}
            <Route exact path="/customer/search-food" component={SearchFood} />
            <Route
              exact
              path="/customer/check_out_item"
              component={CheckOutFood}
            />
            {/* <Route exact path="/store" component={Store} /> */}
            {/* <Route exact path="/storetwo" component={Storetwo} /> */}
            <Route exact path="/customer/items" component={StoreView} />
            {/* <Route exact path="/saved" component={Saved} /> */}

            <Route exact path="/customer/profile" component={Profile} />
            <Route exact path="/customer/help" component={Help} />
            <Route
              exact
              path="/customer/term&conditions"
              component={TermAndCondition}
            />
            <Route
              exact
              path="/customer/help-and-support"
              component={HelpAndSupport}
            />
            <Route exact path="/customer/faq" component={FAQ} />
            <Route exact path="/customer/store/:id" component={Stores} />
            <Route exact path="/customer/table-reservation" component={DineOut} />
            <Route exact path="/customer/reservation-stores" component={ReservationStores} />
            <Route exact path="/customer/reservation-store" component={ReservationStore} />
            <Route exact path="/customer/book-table" component={BookATable} />
            <Route
              exact
              path="/customer/privacy&Policy"
              component={PrivacyPolicy}
            />
           
            {/* <Route exact path="/admin" component={StoreAdmin} /> */}

            {/* <Route path="/customer/about" component={AboutUs} /> */}

            <Route exact component={Error} />
          </Switch>
        </Suspense>
      </Router>
      <button
        onClick={topFunction}
        id="myBtn"
        className={showBtn}
        title="Go to top"
      >
        <i class="fa-duotone fa-up-to-line"></i>
      </button>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
