import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  isLoggedIn: false,
};

// Check if user is logged in based on cookies
const user = Cookies.get('InfoFringaleUser');
if (user) {
  initialState.isLoggedIn = true;
}

export const isLoginSlice = createSlice({
  name: 'isLogin',
  initialState,
  reducers: {
    isAuthenticated: (state) => {
      state.isLoggedIn = true;
    },
    notIsAuthenticated: (state) => {
      state.isLoggedIn = false;
    },
  },
});

export const { isAuthenticated, notIsAuthenticated } = isLoginSlice.actions;

export const selectIsLoggedIn = (state) => state.isLogin.isLoggedIn;

export default isLoginSlice.reducer;
